import { Match, ParentComponent, Switch } from 'solid-js'

type Props = {
	type?: number
	id?: string
	class?: string
}

const Header: ParentComponent<Props> = props => {
	return (
		<Switch>
			<Match when={props.type === 0}>
				<h1 id={`${props.id ?? ''}`} class={`title ${props.class ?? ''}`}>
					{props.children}
				</h1>
			</Match>
			<Match when={props.type === 1}>
				<h2 id={`${props.id ?? ''}`} class={`title ${props.class ?? ''}`}>
					{props.children}
				</h2>
			</Match>
			<Match when={props.type === 2}>
				<h3 id={`${props.id ?? ''}`} class={`title ${props.class ?? ''}`}>
					{props.children}
				</h3>
			</Match>
			<Match when={props.type === 3}>
				<h4 id={`${props.id ?? ''}`} class={`title ${props.class ?? ''}`}>
					{props.children}
				</h4>
			</Match>
			<Match when={props.type === 4}>
				<h2 id={`${props.id ?? ''}`} class={`title h2-light ${props.class ?? ''}`}>
					{props.children}
				</h2>
			</Match>
			<Match when={props.type === 5}>
				<h2 id={`${props.id ?? ''}`} class={`title mb-0 ${props.class ?? ''}`}>
					{props.children}
				</h2>
			</Match>
			<Match when={props.type === 6}>
				<h4 id={`${props.id ?? ''}`} class={`title ${props.class ?? ''}`}>
					{props.children}
				</h4>
			</Match>
			<Match when={props.type === 7}>
				<h3 id={`${props.id ?? ''}`} class={`title h4 ${props.class ?? ''}`}>
					{props.children}
				</h3>
			</Match>
		</Switch>
	)
}

export default Header
